<template>
  <v-dialog v-model="show" :max-width="options.width" persistent scrollable>
    <v-card>
      <dialog-bar v-if="options.expend" @expand="$emit('expand', $event)"></dialog-bar>
      <v-card-title>
        <span class="headline">
          <v-icon>{{ options.icon }}</v-icon
          >{{ options.title }}
        </span>
      </v-card-title>
      <v-card-subtitle class="my-3 pb-0" v-if="options.subtitle && options.subtitle.length > 0">
        {{ options.subtitle }}
      </v-card-subtitle>
      <slot name="header" :options="options" :records="dataRecords"></slot>
      <v-divider class="my-2"></v-divider>
      <v-card-text :style="{ height: options.height }">
        <!-- <v-row no-gutters class="mb-2">
          <v-col>
            <v-btn class="float-right" small color="accent">
              <v-icon small>mdi-download</v-icon>エラー情報ダウンロード
            </v-btn>
          </v-col>
        </v-row> -->
        <v-row no-gutters>
          <v-col>
            <ag-grid-vue
              class="ag-theme-alpine"
              :gridOptions="gridOptions"
              v-model="dataRecords"
              @row-data-changed="onRowDataChanged"
            ></ag-grid-vue>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" @click="onCancel">{{ options.btnCancel }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="onSubmit">{{ options.btnSubmit }}</v-btn>
      </v-card-actions>
    </v-card>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<style lang="scss">
@import "../../../node_modules/ag-grid-community/dist/styles/ag-grid.css";
@import "../../../node_modules/ag-grid-community/dist/styles/ag-theme-alpine.css";
</style>

<script>
import { AgGridVue } from "ag-grid-vue";
import { AG_GRID_LOCALE_JA } from "../../models/ag-grid/locales";
import { CheckboxCellRenderer } from "../../components/ag-grid/cellRenderers";
import {
  NumericColumn,
  PercentColumn,
  DateColumn,
  FullDateColumn,
  CheckmarkColumn,
  EditableColumn,
  EditableDateColumn,
} from "../../models/ag-grid/columnTypes";
import { DateCellEditor, CheckboxCellEditor } from "../../components/ag-grid/cellEditors";
import DialogBar from "./DialogBar.vue";

export default {
  name: "ConfirmGridDialog",
  props: {
    icon: { type: String, default: "mdi-checkbox-marked-circle-outline" },
    title: { type: String, default: "対象確認" },
    subtitle: { type: String, default: null },
    btnSubmit: { type: String, default: "確定" },
    btnCancel: { type: String, default: "キャンセル" },
    width: { type: String, default: "100%" },
    height: { type: String, default: "350px" },
    columns: { type: Array, default: () => [] },
    records: { type: Array, default: () => [] },
    messageField: { type: String, default: "_messages" },
    autoClose: { type: Boolean, default: true },
    expend: { type: Boolean, default: false },
  },
  components: {
    AgGridVue,
    DialogBar,
  },
  data: function () {
    return {
      show: false,
      options: {
        icon: this.icon,
        title: this.title,
        subtitle: this.subtitle,
        btnSubmit: this.btnSubmit,
        btnCancel: this.btnCancel,
        width: this.width,
        height: this.height,
        messageField: this.messageField,
        autoClose: this.autoClose,
        expend: this.expend,
      },
      callback: {
        submit: null,
        cancel: null,
      },
      gridOptions: {
        defaultColDef: {
          resizable: true,
          sortable: false,
          filter: false,
          editable: false,
        },
        columnDefs: this.columns,
        rowSelection: false,
        suppressCellSelection: true,
        singleClickEdit: true,
        pagination: false,
        localeText: AG_GRID_LOCALE_JA,
        domLayout: "autoHeight",
        columnTypes: {
          dpNumericColumn: { ...NumericColumn, filter: false },
          dpPercentColumn: { ...PercentColumn, filter: false },
          dpDateColumn: DateColumn,
          dpFullDateColumn: FullDateColumn,
          dpCheckmarkColumn: CheckmarkColumn,
          dpEditableColumn: EditableColumn,
          dpEditableDateColumn: EditableDateColumn,
        },
        frameworkComponents: {
          dpDateCellEditor: DateCellEditor,
          dpCheckboxCellRenderer: CheckboxCellRenderer,
          dpCheckboxCellEditor: CheckboxCellEditor,
        },
      },
      dataRecords: this.records,
    };
  },
  watch: {
    icon(value) {
      this.options.icon = value;
    },
    title(value) {
      this.options.title = value;
    },
    subtitle(value) {
      this.options.subtitle = value;
    },
    btnSubmit(value) {
      this.options.btnSubmit = value;
    },
    btnCancel(value) {
      this.options.btnCancel = value;
    },
    width(value) {
      this.options.width = value;
    },
    height(value) {
      this.options.height = value;
    },
    columns(value) {
      this.gridOptions.api.setColumnDefs(value);
    },
    records(value) {
      // this.gridOptions.api.setRowData(value);
      this.dataRecords = value;
    },
  },
  computed: {
    isLoading() {
      return this.$store.getters["ui/isLoading"];
    },
  },
  methods: {
    open(options = {}) {
      if ("icon" in options) this.options.icon = options.icon;
      if ("title" in options) this.options.title = options.title;
      if ("subtitle" in options) this.options.subtitle = options.subtitle;
      if ("btnSubmit" in options) this.options.btnSubmit = options.btnSubmit;
      if ("btnCancel" in options) this.options.btnCancel = options.btnCancel;
      if ("width" in options) this.options.width = options.width;
      if ("height" in options) this.options.width = options.height;
      if ("columns" in options) {
        if (this.gridOptions.api) {
          this.gridOptions.api.setColumnDefs(options.columns);
          // this.gridOptions.api.refreshHeader();
        } else {
          this.gridOptions.columnDefs = options.columns;
        }
      }
      if ("records" in options) {
        // if (this.gridOptions.api) {
        //   this.gridOptions.api.setRowData(options.records);
        // } else {
        //   this.dataRecords = options.records;
        // }
        this.dataRecords = options.records;
      }
      this.callback.validate = options.onValidate;
      this.callback.validateRow = options.onValidateRow;
      this.callback.submit = options.onSubmit;
      this.callback.cancel = options.onCancel;
      this.show = true;
    },
    close() {
      this.show = false;
    },
    onSubmit() {
      this.gridOptions.api.stopEditing(false);
      let isValid = true;
      if (typeof this.callback.validate === "function") {
        isValid = this.callback.validate(this.dataRecords, this.gridOptions);
      }
      if (isValid && typeof this.callback.validateRow === "function") {
        this.gridOptions.api.forEachNode((row) => {
          const validateMessage = this.callback.validateRow(row, this.dataRecords, this.gridOptions);
          if (validateMessage === true) {
            row.setDataValue(this.options.messageField, "");
          } else {
            row.setDataValue(this.options.messageField, validateMessage);
            isValid = false;
          }
        });
        this.gridOptions.columnApi.setColumnVisible(this.options.messageField, !isValid);
      }
      if (isValid) {
        if (this.options.autoClose) {
          this.close();
        }
        this.$emit("submit", this.dataRecords);
        if (typeof this.callback.submit === "function") {
          this.callback.submit(this.dataRecords);
        }
      } else {
        this.$dialog.error({ text: "入力エラーがあります" });
      }
    },
    onCancel() {
      this.close();
      this.$emit("cancel");
      if (typeof this.callback.cancel === "function") {
        this.callback.cancel();
      }
    },
    onRowDataChanged() {
      this.gridOptions.columnApi.autoSizeAllColumns();
    },
  },
};
</script>
